<template>
  <div class="thank-you full-height">
    <v-container class="full-height thank-you-container" fluid>
      <v-row>
        <v-col
          cols="12"
        >
          <h1>
            {{ $t(`thankYou.title`) }}
          </h1>
          <p>
            {{ $t(`thankYou.p1`) }}
          </p>
          <p>
            {{ $t(`thankYou.p2`) }}
          </p>
        </v-col>
        <v-col>
          <v-card class="logo-card" @click="redirectToJetfloatHomePage()">
            <v-img
              src="../assets/logo_jetfloat.svg"
              class="black--text align-end logo-card-image"
              height="200px"
            >
              
            </v-img>
            <v-card-title class="logo-card-title">
              {{ $t(`thankYou.cards.webpage`) }}
            </v-card-title>
            <v-card-actions>
              <v-btn block>
                <v-icon>mdi-web</v-icon>      
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col>
          <v-card class="logo-card" @click="redirectToConfiguratorHomePage()">
            <v-img
              src="../assets/logo_jetfloat.svg" 
              class="black--text align-end logo-card-image"
              height="200px"
            >
              
            </v-img>
            <v-card-title class="logo-card-title">
              {{ $t(`thankYou.cards.newProject`) }}
            </v-card-title>
            <v-card-actions>
              <v-btn block>
                <v-icon>mdi-open-in-new</v-icon>      
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>

export default {

  name: 'ThankYouPage',
  data () {
    return {
      
    }
  },

  methods: {
    redirectToJetfloatHomePage() {
      window.location.href = 'https://www.jetfloat-international.com/';
    },
    redirectToConfiguratorHomePage() {
      window.location.href = '/';
    },
  }
}
</script>